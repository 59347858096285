/* eslint-disable */
import React, { useContext } from 'react';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import useProductDataByCountry from '../../hooks/useProductDataByCountry';
import totalPriceCarts from '../../hooks/totalCarts';

const organizeDataPayments = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const { carts, products, order } = productContext;
  const { address, hasAddress } = paymentContext;
  const {  isCitaFlow, country, phone } = globalContext;
  const [productsToShow, setProductsToShow] = React.useState([]);
  const totalCarts = totalPriceCarts(carts, false, order);
  const { currencyCode } = carts[0].totalPrice;
  localStorage.setItem('email', carts[0].customerEmail);

  if (productsToShow.length < products.length) {
    const converted = useProductDataByCountry(products, false, false);
    converted.forEach((obj) => {
      delete obj.images; // eslint-disable-line no-param-reassign
    });
    setProductsToShow(converted);
  }

  const agentName = new URLSearchParams(location.search).get('agentName');
  const agentId = new URLSearchParams(location.search).get('agentId');
  const clinicId = new URLSearchParams(location.search).get('clinicId');
  const addresLocal = {
      Street: hasAddress ? address.Street : 'street',
      Address_Number: hasAddress ? address.Address_Number : '123',
      Zip_Code: hasAddress ? address.Zip_Code : '00000',
      Neighborhood: hasAddress ? address.Neighborhood : 'colony',
      State: hasAddress ? address.State[0] : 'state',
      city: 'Ciudad',
      References: hasAddress ? address.References : 'reference',
    };
  
  
  const data = {
    payer: {
      address: addresLocal,
      name: localStorage.getItem('name'),
      phone: phone === '' ? '000000000000' : phone,
      email: carts[0].customerEmail,
      customerId: carts[0].customerId? carts[0].customerId: carts[0].anonymousId,
    },
    cartData: {
      id: carts[0].id,
      currency: currencyCode,
      totalPrice: totalCarts,
      totalLineItemQuantity: products.length,
      lineItems: productsToShow,
      productCategory: isCitaFlow ? 'appointment' : '',
      productKey: isCitaFlow ? `appointment-${country.toLowerCase()}` : '',
      discount: carts[0].discountOnTotalPrice?.discountedAmount,
    },
  };
  
  // Update request sending Order information
  if(order) {
    delete data.cartData.id;
    data.cartData.orderId = order.id;
    data.cartData.totalPrice = order.custom.fields.amountToBePaid;
    data.cartData.totalLineItemQuantity = 1;
    data.cartData.lineItems = [
      {
        "id": "futura-payment-"+(new Date().getTime()),
        "productId": "payment-" + order.custom.fields.amountToBePaid,
        "name": "Pago por " + order.custom.fields.amountToBePaid,
        "price": order.custom.fields.amountToBePaid*100,
        "quantity": 1,
        "totalPrice": order.custom.fields.amountToBePaid*100
      }
    ]
  }

  if (agentId !== null && agentName !== null && clinicId !== null) {
    data.salesAgentId = agentId;
    data.nameAgent = agentName;
    data.clinicId = clinicId;
  }

  localStorage.setItem('address', JSON.stringify(addresLocal));

  return data;
};

export default organizeDataPayments;
