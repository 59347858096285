/* eslint-disable */
import React from 'react';
import FlowCreditCard from './components/CreditCard';
import FlowEfecty from './components/Efecty';
import FlowOxxo from './components/Oxxo';
import FlowSpei from './components/SPEI';
import FlowPSE from './components/PSE';
// import FlowPayPal from './components/PayPal';
import FlowCloseOrder from './components/CloseOrder';
import FlowEfectivo from './components/Efectivo';
import FlowCulqui from './components/Culqui';

const dataPayments = {
  MX: {
    payments: [
      {
        icon: 'credit',
        label: 'Tarjeta',
        show: true,
        type: 'card',
      },
      /*{
        icon: 'paypal',
        label: 'PayPal',
        show: false,
        type: 'paypal',
      },*/
      {
        icon: 'cash',
        label: 'Efectivo',
        show: true,
        type: 'oxxo',
      },
      {
        icon: 'spei',
        label: 'Transferencia',
        show: true,
        type: 'spei',
      },
    ],
    card: () => <FlowCreditCard />,
    oxxo: () => <FlowOxxo />,
    spei: () => <FlowSpei />,
    //paypal: () => <FlowPayPal />,
    closeOrder: () => <FlowCloseOrder />,
  },
  CO: {
    payments: [
      {
        icon: 'credit',
        label: 'Tarjeta',
        show: true,
        type: 'card',
      },
      {
        icon: 'pse',
        label: 'PSE',
        show: true,
        type: 'pse',
      },
      {
        icon: 'cash',
        label: 'Efectivo',
        show: true,
        type: 'efecty',
      },
    ],
    pse: () => <FlowPSE />,
    card: () => <FlowCreditCard />,
    efecty: () => <FlowEfecty />,
    closeOrder: () => <FlowCloseOrder />,
  },
  PE: {
    payments: [
      {
        icon: 'credit',
        label: 'Tarjeta',
        show: true,
        type: 'card',
      },
      {
        icon: 'cash',
        label: 'Efectivo',
        show: true,
        type: 'efectivo',
      },
      {
        icon: 'spei',
        label: 'Culqui',
        show: true,
        type: 'culqui',
      } 
    ],
      card: () => <FlowCreditCard />,
      efectivo: () => <FlowEfectivo />,
      culqui: () => <FlowCulqui />,
      closeOrder: () => <FlowCloseOrder />,
    },
};

export default dataPayments;
